body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#page-content {
  position: relative;
  /* this element's width controls the effective height */
  /* of the video container's padding-bottom */
  max-width: 640px;
  margin: 10px auto;
}

#video-container {
  position: relative;
  /* forces the container to match a 16x9 aspect ratio */
  /* replace with 75% for a 4:3 aspect ratio, if needed */
  padding-bottom: 56.25%;
}

#video-element {
  /* forces the contents to fill the container */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#ad-container {
  display: none;
}