#truncateLongTexts {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#publisher {
    height: 18px;
    padding-left: 10px;
    padding-bottom: 8px;
    padding-top: 0px;
    padding-right: 10px;
}

#largePlayerTitle {
    width: inherit;
    line-height: 10px;
    padding-top: 3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#largePlayerDescription {
    width: inherit;
    line-height: 10px;
    padding-top: 3px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#title {
    width: 165px;
    height: 42px;
    padding-left: 10px;
    padding-top: 11px;
    line-height: 15px;
    font-size: 10pt;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#visual-title {
    width: 100%;
    height: 75%;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 16px;
    line-height: 19px;
    font-size: 12pt;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#large-title {
    width: 100%;
    height: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    line-height: 18px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#dateInfo {
    padding-top: 0px;
    padding-left: 10px;
}

#title-background {
    position: "absolute";
    top: 120px;
    width: 200px;
    height: 40px;
    opacity: 0.83;
}

@media only screen and (max-width: 900px) {
    #truncateLongTexts {
        max-width: 800px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 480px) {
    #truncateLongTexts {
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 400px) {
    #truncateLongTexts {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 360px) {
    #truncateLongTexts {
        max-width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 320px) {
    #truncateLongTexts {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (min-width: 480px) {
    #truncateLongTexts {
        max-width: 650px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (min-width: 900px) {
    #truncateLongTexts {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (min-width: 1000px) {
    #truncateLongTexts {
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (min-width: 1200px) {
    #truncateLongTexts {
        max-width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
