#flag-img-language {
    height: 16px;
    width: 16px;
    object-fit: cover;
    border-radius: 16px;
    outline: 1px solid rgba(137, 138, 138, 0.2);
    margin-top: 5px;
    margin-right: 2px;
  }

#flag-img-language-selection {
    height: 20px;
    width: 20px;
    object-fit: cover;
    border-radius: 16px;
    outline: 1px solid rgba(137, 138, 138, 0.2);
  }


#flag-img-voice {
  height: 14px;
  width: 16px;
  object-fit: cover;
  border-radius: 16px;
  outline: 1px solid rgba(137, 138, 138, 0.2);
  margin-top: 6px;
  margin-right: 2px;
}


